(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict"; // legacy browser alert

function isLegacyIE() {
  var ua = navigator.userAgent;

  if (ua.indexOf("MSIE 6.0") != -1) {
    // Internet Explorer 6
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 7.0") != -1) {
    // Internet Explorer 7
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 8.0") != -1) {
    // Internet Explorer 8
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 9.0") != -1) {
    // Internet Explorer 9
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 10.0") != -1) {
    // Internet Explorer 10
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else {
    return false;
  }
}

isLegacyIE(); // random slide

var slideWrap = document.querySelector('.js-slide-wrap');
var slideItems = Array.from(document.querySelectorAll('.js-slide-item'));
var randomNumbers = [];

if (slideWrap) {
  for (var i = 0; i < slideItems.length; i++) {
    randomNumbers.push(Math.random());
  }

  var sortedItems = slideItems.sort(function (a, b) {
    return randomNumbers[slideItems.indexOf(a)] - randomNumbers[slideItems.indexOf(b)];
  });

  while (slideWrap.firstChild) {
    slideWrap.removeChild(slideWrap.firstChild);
  }

  sortedItems.forEach(function (item) {
    slideWrap.appendChild(item);
  });
} // jQuery


$(function () {
  // roll over image
  $(".roll_over").each(function () {
    $(this).on('mouseenter', function () {
      var hoverImagePath = $(this).attr('data-hover');
      $(this).attr('src', hoverImagePath);
    });
    $(this).on('mouseleave', function () {
      var srcImagePath = $(this).attr('data-src');
      $(this).attr('src', srcImagePath);
    });
  }); // dropdown menu

  $(window).on('resize', function () {
    if (window.matchMedia("(min-width:768px)").matches) {
      $(".drop_down_trigger").each(function () {
        $(this).on('mouseenter', function () {
          $(this).children('.drop_down').stop().slideDown();
        });
        $(this).on('mouseleave', function () {
          $(this).children('.drop_down').stop().slideUp();
        });
      });
    } else {
      $(".drop_down_trigger").each(function () {
        $(this).on('mouseenter mouseleave', function () {
          $(this).children('.drop_down').stop().css('display', 'none');
        });
      });
    }
  }); // scroll top

  $("#scroll_top").on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  }); // mobile global navi

  $("#hamburger").on('click', function () {
    $("#global_navi_mobile").slideToggle();
    $(this).toggleClass('__active');
  });
  $(window).on('resize', function () {
    if (window.matchMedia("(min-width:768px)").matches) {
      $("#global_navi_mobile").slideUp();
      $("#hamburger").removeClass('__active');
    }
  }); // column panel

  $(".panel_open_trigger").each(function () {
    $(this).on('click', function () {
      $(this).siblings('.__body').slideToggle();
    });
  });
  $(".column_close").each(function () {
    $(this).on('click', function () {
      $(this).closest('.__body').slideUp();
    });
  }); // topics panel

  $(".js-accordion-list-topics").each(function () {
    $(this).on('click', function () {
      $(this).next().slideToggle();
      var icon = $(this).children('.__icon').text();

      if (icon == '▲') {
        $(this).children('.__icon').text('▼');
      } else {
        $(this).children('.__icon').text('▲');
      }
    });
  });
});
$(window).on('load', function () {
  // dropdown menu
  if (window.matchMedia("(min-width:768px)").matches) {
    $(".drop_down_trigger").each(function () {
      $(this).on('mouseenter', function () {
        $(this).children('.drop_down').stop().slideDown();
      });
      $(this).on('mouseleave', function () {
        $(this).children('.drop_down').stop().slideUp();
      });
    });
  } else {
    $(".drop_down_trigger").each(function () {
      $(this).on('mouseenter mouseleave', function () {
        $(this).children('.drop_down').stop().css('display', 'none');
      });
    });
  }

  if ($('#slide').length !== 0) {
    setTimeout(function () {
      var mySwiper = new Swiper('#slide', {
        loop: true,
        autoplay: {
          delay: 4000
        },
        speed: 1000,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        }
      });
    }, 500);
  }
});

},{}]},{},[1]);
